@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 128px;
  height: 128px;
  margin-top: -64px;
  margin-left: -64px;
  border-radius: 50%;
  border: 8px solid #ccc;
  border-top-color: var(--primary);
  animation: spinner 1.5s ease-in-out infinite;
}
